import React, { Component } from 'react';
import './ClientsComp.scss';
import { Carousel, Container, Row } from 'react-bootstrap';
import slide1 from '../../images/slide1.jpg';
import slide2 from '../../images/slide2.jpg';
import slide3 from '../../images/slide3.jpg';
import logo from '../../images/logo-white.png';
import BEMHelper from 'react-bem-helper';

const BEM = new BEMHelper('clients');

export default class ClientsComp extends Component {
  render() {
    return (
      <>
        <div {...BEM('', '', 'justify-content-md-center')}>
          <div {...BEM('', 'logo')}>
            <img src={logo} />
          </div>
          <Carousel controls={false}>
            <Carousel.Item>
              <img className='d-block w-100' src={slide1} alt='First slide' />
              <Carousel.Caption>
                <h2>
                  “The key is to set realistic customer expectations, and then
                  not to just meet them, but to exceed them — preferably in
                  unexpected and helpful ways.”
                </h2>
                <p>
                  We pride ourselves on doing our best to make sure every client
                  is our number one priority, no matter what your requirement.
                  Everyone that steps through our door is important to us. You,
                  as the customer, have the biggest impact on our business and
                  as such we invite you to try our services. It is our promise
                  to go the extra mile, whether it is in advise, design, print
                  or production.
                </p>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className='d-block w-100' src={slide2} alt='Second slide' />

              <Carousel.Caption>
                <h2>
                  "Customer service shouldn't just be a department, it should be
                  the entire company."
                </h2>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img className='d-block w-100' src={slide3} alt='Third slide' />

              <Carousel.Caption>
                <h2>
                  "Happy customers are your biggest advocates and can become
                  your most successful sales team."
                </h2>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        </div>
      </>
    );
  }
}
