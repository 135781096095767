import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { services } from './services';
import './servicesComp.scss';
import BEMHelper from 'react-bem-helper';
const BEM = new BEMHelper('services');

export default class ServicesComp extends Component {
  render() {
    return (
      <>
        <Row {...BEM('')}>
          {services.map((s, i) => {
            return (
              <Col md={4} {...BEM('', 'block')} key={i}>
                <div {...BEM('', 'center-block')}>
                  <h4>{s.title}</h4>
                  <p>{s.text}</p>
                </div>
              </Col>
            );
          })}
        </Row>

        <Row {...BEM('service-text', '', 'justify-content-md-center')}>
          <Col md={7}>
            <p {...BEM('', 'servicetext-para')}>
              We are not limited to the above mentioned services, should you
              have a custom requirement give us a call or send us and email and
              we will make a plan to help you.
            </p>
          </Col>
        </Row>
      </>
    );
  }
}
