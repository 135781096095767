import React, { Component } from 'react';
import BEMHelper from 'react-bem-helper';
import { Container } from 'react-bootstrap';
import ContactComp from '../Contact/ContactComp';
import ServicesComp from '../Services/ServicesComp';
import FloatingWhatsApp from 'react-floating-whatsapp';
import 'react-floating-whatsapp/dist/index.css';
import './LandingComp.scss';

const BEM = new BEMHelper('landing');

export default class LandingComp extends Component {
  render() {
    return (
      <div {...BEM()}>
        <Container>
          <ServicesComp />
          <ContactComp />
        </Container>
        <FloatingWhatsApp
          {...{
            phoneNumber: '27665712033',
            accountName: 'Pinpoint Print',
            chatMessage: 'Hello there! \nHow can we help?',
            statusMessage: 'Email: info@pinpointprint.co.za',
          }}
          darkMode
        />
      </div>
    );
  }
}
