import React, { Component } from 'react';
import logo from '../../images/logo.png';
import './NavbarComp.scss';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faHome, faPhone } from '@fortawesome/free-solid-svg-icons';
import BEMHelper from 'react-bem-helper';

const BEM = new BEMHelper('menu');

export default class NavbarComp extends Component {
  render() {
    return (
      <>
        <div className='topbar'>
          <Container {...BEM('', '', 'justify-content-md-center')}>
            <ul>
              <li>
                <FontAwesomeIcon icon={faPhone} />
                <a href='tel:0429406788'>042 940 6788</a>
              </li>
              <li>
                <FontAwesomeIcon icon={faEnvelope} />
                <a href='mailto:info@pinpointprint.co.za'>
                  info@pinpointprint.co.za
                </a>
              </li>
              <li>
                <FontAwesomeIcon icon={faHome} />
                <a
                  href='https://www.google.co.za/maps/place/Pinpoint+print/@-34.1659214,24.8279249,18.75z/data=!4m12!1m6!3m5!1s0x1e7a5928041975a9:0xfd5e23d1664d6a62!2sPinpoint+print!8m2!3d-34.1659984!4d24.8283569!3m4!1s0x1e7a5928041975a9:0xfd5e23d1664d6a62!8m2!3d-34.1659984!4d24.8283569'
                  target='blank'
                >
                  1 Phillippa Place, St Francis Drive, St Francis
                </a>
              </li>
            </ul>
          </Container>
        </div>
        {/* <Navbar bg='light' expand='lg' sticky='top'>
          <Container>
            <Navbar.Brand href='#home'>
              <img src={logo} className='logo' />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse id='basic-navbar-nav'>
              <Nav className='ml-auto'>
                <Nav.Link href='#home'>Home</Nav.Link>
                <Nav.Link href='#link'>Link</Nav.Link>
                <NavDropdown title='Dropdown' id='basic-nav-dropdown'>
                  <NavDropdown.Item href='#action/3.1'>Action</NavDropdown.Item>
                  <NavDropdown.Item href='#action/3.2'>
                    Another action
                  </NavDropdown.Item>
                  <NavDropdown.Item href='#action/3.3'>
                    Something
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href='#action/3.4'>
                    Separated link
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar> */}
      </>
    );
  }
}
