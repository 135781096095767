import React, { Component } from 'react';
import BEMHelper from 'react-bem-helper';
import { Col, Container, Row } from 'react-bootstrap';
import logo from '../../images/logo.png';
import './FooterComp.scss';

const BEM = new BEMHelper('footer');

export default class FooterComp extends Component {
  render() {
    return (
      <div {...BEM()}>
        <Container>
          <Row>
            <Col md='4'>
              <img src={logo} {...BEM('logo')} />
              <div {...BEM('logo-text')}>
                We are a Phone call, Whatsapp message or eMail away from
                delivering the service you deserve.
              </div>
            </Col>
            <Col md='4'>
              <h3>Services</h3>
              <ul>
                <li>Print</li>
                <li>Design</li>
                <li>Signage</li>
                <li>Canvas</li>
                <li>Digital Media Duplication</li>
                <li>Websites</li>
              </ul>
            </Col>
            <Col md='4'>
              <h3>Business Hours</h3>
              <ul>
                <li>
                  <span {...BEM('bold')}>Monday - Friday</span> 08:00 to 17:00
                </li>
                <li>
                  <span {...BEM('bold')}>Saturday / Public Holidays</span> 09:00
                  - 12:00
                </li>
                <li>
                  <span {...BEM('bold')}>Sunday</span> Closed
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
