import LandingComp from './Components/Landing/LandingComp';
import NavbarComp from './Components/Navbar/NavbarComp';
import ClientsComp from './Components/Clients/ClientsComp';
import FooterComp from './Components/Footer/FooterComp';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';

export default function App() {
  return (
    <>
      <NavbarComp />
      <ClientsComp />
      <LandingComp />

      <FooterComp />
    </>
  );
}
