import React, { Component } from 'react';
import BEMHelper from 'react-bem-helper';
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  Row,
} from 'react-bootstrap';
import './ContactComp.scss';
import emailjs from 'emailjs-com';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const BEM = new BEMHelper('contact');

class ContactComp extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      valid: false,
      invalid: false,
      spin: false,
    };
  }

  handleSubmit(e: any) {
    this.setState({ spin: true });
    e.preventDefault();
    emailjs
      .sendForm(
        'service_js4s11x',
        'template_6aehdh4',
        e.target,
        'user_h8SWMVABVGxu8vZddKdPU'
      )
      .then(
        (result) => {
          console.log(result.text);
          e.target.reset();
          this.setState({ valid: true, spin: false });
        },
        (error) => {
          console.log(error.text);
          this.setState({ invalid: true, spin: false });
        }
      );

    // info@pinpointprint.co.za
  }

  render() {
    const isSpin = () => {
      return (
        <>
          <Row {...BEM('spinner-container', '', 'justify-content-md-center')}>
            <FontAwesomeIcon
              icon={faSpinner}
              spin
              size='lg'
              {...BEM('spinner-container', 'spinner')}
            />
          </Row>
        </>
      );
    };

    const isValid = () => {
      return (
        <>
          <Row {...BEM('', '', 'justify-content-md-center')}>
            <h3 {...BEM('Valid', 'success', 'text-center')}>
              Form successfully send!
            </h3>
          </Row>
        </>
      );
    };
    const isNotValid = () => {
      return (
        <>
          <Row {...BEM('', '', 'justify-content-md-center')}>
            <h3 {...BEM('Valid', 'fail', 'text-center')}>
              OOPS! check form again please...
            </h3>
          </Row>
        </>
      );
    };

    return (
      <>
        {this.state.valid && isValid()}
        {this.state.invalid && isNotValid()}
        {this.state.spin && isSpin()}

        <Row {...BEM('', '', 'justify-content-md-center')}>
          <Col md='8' {...BEM('', 'contactblock')}>
            <Form onSubmit={this.handleSubmit} id='contact-form'>
              <FormGroup>
                <Row>
                  <Col>
                    <FormControl
                      type='text'
                      placeholder='Full Name'
                      name='from_name'
                    ></FormControl>
                  </Col>
                  <Col>
                    <FormControl
                      type='email'
                      placeholder='Email'
                      name='from_email'
                    ></FormControl>
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Row>
                  <Col>
                    <FormControl
                      type='text'
                      placeholder='Subject'
                      name='subject'
                    ></FormControl>
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <FormControl
                  as='textarea'
                  placeholder='Tell us about your requirement...'
                  name='message'
                />
              </FormGroup>
              <Button type='submit' onClick={isSpin}>
                Send
              </Button>
            </Form>
          </Col>
        </Row>
      </>
    );
  }
}

export default ContactComp;
