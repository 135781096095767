export const services = [
  {
    title: 'Print',
    text: 'Let us print your business cards, menus, flyers, contracts, labels, poster or building plans. Anything up A0 size.',
  },
  {
    title: 'Design',
    text: 'Whether you need a logo, flyer, menu or card designed we will make sure you are happy with the end result.',
  },
  {
    title: 'Signage',
    text: 'We do sign boards, vehicle branding, indoor signage, sandblast vinyl, UV film, contravision.',
  },
  {
    title: 'Canvas',
    text: 'Our canvasses are already printed and stretched, ready to hang the moment you receive it',
  },
  {
    title: 'Digital Media Duplication',
    text: "Training material can also include cd's, dvd's and Blue-Ray discs for schools and training facilities, We can duplicate your material in a heartbeat. We do not infringe on copyright material.",
  },
  {
    title: 'Websites',
    text: 'Any business should have proper online representation and that should start with a well planned, designed and developed website.',
  },
];
